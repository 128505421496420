import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function SignIn() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();

    // Function to get the token from URL or localStorage
    const getTokenFromUrl = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token) {
            localStorage.setItem('access_token', token);
            return token;
        }
        return localStorage.getItem('access_token');
    };

    // Fetch user data if token is available
    useEffect(() => {
        const token = getTokenFromUrl();
        if (token) {
            fetch('https://notitia.buzz/api/auth/user', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Not authenticated');
                })
                .then(data => {
                    setIsLoggedIn(true);
                    setUserData(data);
                })
                .catch(() => {
                    setIsLoggedIn(false);
                    localStorage.removeItem('access_token');
                });
        }
    }, []);

    // Handle logout
    const handleLogout = () => {
        const token = localStorage.getItem('access_token');
        if (token) {
            fetch('https://notitia.buzz/api/auth/logout', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
                .then(response => {
                    if (response.ok) {
                        // Remove token and user data
                        localStorage.removeItem('access_token');
                        setIsLoggedIn(false);
                        setUserData(null);

                        // Redirect to the homepage or login page
                        navigate('/'); // Use this to redirect to the homepage
                    } else {
                        return response.json().then(data => {
                            console.error('Logout failed:', data.message);
                        });
                    }
                })
                .catch(error => {
                    console.error('Logout failed:', error);
                });
        }
    };



    return (
        <div>
            {isLoggedIn ? (
                <div>
                    <h1>Welcome, {userData?.name}</h1>
                    <img src={userData?.avatar} alt="User Avatar" />
                    <p>Email: {userData?.email}</p>
                    <button onClick={handleLogout}>Logout</button>
                </div>
            ) : (
                <div>
                    <h1>You are not logged in</h1>
                    <a href="https://notitia.buzz/api/auth">Google Sign In</a>
                </div>
            )}
        </div>
    );
}

export default SignIn;
